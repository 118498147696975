// ==========================================================================
// Offcanvas Styles
// ==========================================================================

#offcanvas {
	position: fixed;
	top: 0;
	right: -250px;
	width: 250px;
	height: 100%;
	padding: ($global-spacing * 2) $global-spacing;
	background: $brand-color;
	transition: transform 0.5s;
	z-index: 1;

	.navmenu,
	.toplinks {
		padding: 0 $global-spacing;

		li {
			display: block;
			margin-bottom: 0.5em;
		}

		a {
			color: #fff;
			padding: rem( 2px ) 0;
			border-bottom: 3px solid transparent;
			font-size: em( 18px );
			font-weight: 700;
			outline: none;

			&:hover,
			&:focus {
				border-bottom-color: #fff;
			}
		}

		.sub-menu {
			position: initial;
			opacity: 1;
			transform: scale( 1, 1 );
			padding: 0 $global-spacing;
			box-shadow: none;

			li {
				visibility: visible;

				a {
					display: block;
					padding: ($global-spacing / 2) 0;
				}
			}
		}
	}

	.search-form {
		position: relative;
		margin-top: $global-spacing;

		label {
			margin-bottom: 0;
		}
	}

	.search-field {
		@include selector( 'placeholder' ) {
			color: darken( #fff, 10 );
		}

		padding: 10px 40px 10px 20px;
		color: #fff;
		background: transparent;
		border-color: #fff;
		line-height: 1;
	}

	.search-submit {
		color: #fff;
		font-size: em( 20px );
		margin-top: -2px;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translateY( -50% );
		right: 15px;
		background: transparent;
		border: 0;
		z-index: 1;

		@media screen and (min-width: 768px) {
			&:hover,
			&:focus {
				color: darken( #fff, 10 );
			}

			&:focus {
				outline-color: darken( #fff, 10 );
			}
		}
	}

	.offcanvas-active & {
		transform: translateX( 0 );
		box-shadow: 0 10px 30px 3px rgba( 0, 0, 0, 0.3 );
		overflow: auto;
	}
}

#offcanvas-toggle {
	@include center( 'Y' );
	right: 15px;
	padding: 0.25em 0.5em;
	border-radius: 5px;

	.icon-bars {
		display: inline-block;
		margin-left: 0.5em;
	}

	.icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		background: #fff;

		+ .icon-bar {
			margin-top: 3px;
		}
	}
}

#wrapper {
	transition: transform 0.5s;

	.offcanvas-active & {
		transform: translateX( -250px );
	}
}

body.offcanvas-active {
	overflow: hidden;
}
