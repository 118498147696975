// ==================================================
// TABLE OF CONTENTS
// --------------------------------------------------
//  * Unit base
//  * Breakpoints
//  * Styles
//      - Base
//      - Typography
//      - Headings
//  * Lists
//      - Buttons
//      - Inputs
// ==================================================



//
// Unit base
//
// **************************************************

$em-base:  21px;
$rem-base: 16px;



//
// Breakpoints
//
// **************************************************

$small:  em( 480px );
$medium: em( 768px );
$large:  em( 980px );
$xlarge: em( 1200px );



//
// Styles
//
// **************************************************


//
// Base
//

$global-background: #fff;
$global-color:      #474444;
$global-spacing:    rem( 20px );
$global-radius:     3px;

$brand-color:       #d3420d;
$newsletter-color:  #15828e;
$copyright-color:   #434242;


//
// Typography
//

$global-font-family: 'Lato', sans-serif;
$global-font-weight: 400;
$global-line-height: 1.5;


//
// Headings
//

$heading-font-family: 'Lato', sans-serif;
$heading-font-weight: 700;
$heading-line-height: 1.3;
$heading-color:       #474444;
$heading-sizes: (
	'h1': em( 46px ),
	'h2': em( 36px ),
	'h3': em( 28px ),
	'h4': em( 22px ),
	'h5': em( 18px ),
	'h6': em( 16px )
);



//
// Lists
//
// **************************************************


//
// Buttons
//

$button-list:
'button',
'input[type="button"]',
'input[type="reset"]',
'input[type="submit"]';


//
// Inputs
//

$input-list:
'input[type="color"]',
'input[type="date"]',
'input[type="datetime"]',
'input[type="datetime-local"]',
'input[type="email"]',
'input[type="month"]',
'input[type="number"]',
'input[type="password"]',
'input[type="search"]',
'input[type="tel"]',
'input[type="text"]',
'input[type="time"]',
'input[type="url"]',
'input[type="week"]',
'input:not([type])',
'textarea';
