// ==========================================================================
// Partials Styles
// ==========================================================================

.featured {
	margin: ($global-spacing * 2) 0;
	text-align: center;
}

.search-here {
	width: 80%;
	margin: ($global-spacing * 3) auto 0;

	label {
		width: 100%;
		margin-bottom: 0;
	}

	button {
		display: block;
		width: 75%;
		margin: $global-spacing auto 0;
	}

	@media screen and (min-width: 768px) {
		form {
			display: flex;
		}

		label {
			width: 72%;
			margin-right: 3%;
		}

		button {
			width: 25%;
			margin-top: 0;
		}
	}
}


.tags a {
	color: $brand-color;
	margin-right: 0.75em;
	padding-bottom: 4px;
	border-bottom: 1px solid $brand-color;
	font-weight: 400;

	&:last-child {
		margin-right: 0;
	}

	&:hover,
	&:focus {
		background: $global-color;
		color: #fff;
	}
}


.item-title {
	padding-bottom: 4px;
	font-size: 1.6em;
	font-weight: 700;

	a {
		color: $global-color;
		border-bottom: 1px solid $brand-color;

		&:hover,
		&:focus {
			background: $brand-color;
			color: #fff;
			border-color: #fff;
		}
	}
}


.socials,
.share-this {
	margin: ($global-spacing * 2) 0;
	text-align: center;

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
	}

	a {
		outline: none;
		color: #fff;
		border-bottom: 3px solid transparent;
		display: inline-block;

		&:hover,
		&:focus {
			.fa-circle {
				color: $global-color;
			}
		}

		&:focus {
			border-color: $global-color;
		}
	}

	.fa-circle {
		color: rgba( $global-color, 0.57 );
	}
}


.share-this {
	h4 {
		display: inline-block;
		vertical-align: middle;
		font-size: em( 26px );
		margin: 0 0.5em;
	}

	ul {
		display: inline-block;
	}
}


.callout {
	margin: 1em 0;
	font-size: em( 38px );
	font-weight: 700;
	opacity: 0.9;

	&.testi {
		padding: 0.5em 0;

		span {
			display: block;
			margin-top: 0.5em;
			font-size: 65%;
			font-weight: 400;
		}
	}
}


.our-team {
	width: 80%;
	margin: ($global-spacing * 2) auto;
	text-align: center;
}


.member {
	margin-bottom: ($global-spacing * 2);

	img {
		width: rem( 180px );
		height: rem( 180px );
		border-radius: 50%;
		object-fit: cover;
	}

	h3 {
		font-size: em( 22px );
	}

	.socials {
		margin: $global-spacing 0;
	}
}


.featured-items,
.related-items {
	padding: ($global-spacing * 3) 0;

	.section-title {
		margin: 0 0 ($global-spacing * 2);
		font-size: 2.5em;
		font-weight: 400;
		text-align: center;
	}

	.excerpt {
		margin: ($global-spacing * 1.5) 0 0;
	}

	.info {
		margin: $global-spacing 0 0;

		p {
			margin-bottom: 0.25em;
		}

		span {
			font-weight: 700;
		}
	}
}


.archive-list,
.related-items {
	background: rgba( #bdbcbc, 0.08 );

	.row {
		@media screen and (min-width: 768px) {
			display: flex;

			> div {
				display: flex;
			}
		}
	}

	.item {
		background: #fff;
		margin-bottom: $global-spacing;
		padding: $global-spacing;
		width: 100%;
		height: 100%;

		&-title {
			margin-top: 0;
		}
	}
}


.featured-items {
	.item-title {
		margin-top: $global-spacing;;
	}
}


.related-items {
	.single-work & .item {
		text-align: center;
	}
}


.archive-list,
.work-list {
	padding: ($global-spacing * 3) 0;
}

.work-list {
	.item {
		width: 90%;
		margin: ($global-spacing * 2) auto;

		@media screen and (min-width: 768px) {
			width: 47.5%;

			&:nth-child( 2n ) {
				float: right;
			}
		}
	}
}
