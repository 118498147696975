// ==========================================================================
// Header Styles
// ==========================================================================

.site-header {
	background: #fff;
	padding: rem( 15px ) 0;

	.bottomsection {
		margin-top: rem( 100px );
	}

	.branding {
		a {
			float: left;
		}

		.site-title {
			width: 300px;
			transition: all 0.2s ease-in-out;

			@media screen and (max-width: 480px) {
				width: 225px;
			}

			@media screen and (max-width: 380px) {
				width: 150px;
			}
		}
	}

	.sitenav {
		padding-top: ($global-spacing / 2);
		padding-bottom: ($global-spacing / 2);

		.menu {
			float: right;

			li:hover,
			li.active {
				> a {
					color: $link-color;
					border-bottom-color: $link-color;

					&:focus {
						outline: 1px solid $link-color;
					}
				}
			}

			li > a:focus {
				color: $link-color;
				border-bottom-color: $link-color;
			}
		}

		.sub-menu {
			a {
				color: #fff;
			}

			li:hover,
			li.active {
				> a {
					color: #fff;
					border-bottom-color: #fff;

					&:focus {
						outline: 1px solid #fff;
					}
				}
			}

			li > a:focus {
				color: #fff;
				border-bottom-color: #fff;
			}
		}
	}

	.topsection {
		position: absolute;
		top: 0;
		right: 15px;
	}

	.toplinks {
		margin: rem( 15px ) 0;
		text-align: right;

		li:hover,
		li.active {
			a {
				border-bottom-color: $brand-color;
				color: $brand-color;

				&:focus {
					outline: 1px solid $link-color;
				}
			}
		}

		a:focus {
			color: $link-color;
			border-bottom-color: $brand-color;
		}
	}

	.search-form {
		position: relative;
		color: #2a2929;

		label {
			margin-bottom: 0;
		}
	}

	.search-field {
		padding: 10px 40px 10px 20px;
		font-size: em( 18px );
		line-height: 1;

		@include selector( 'placeholder' ) {
			color: #767676;
		}
	}

	.search-submit {
		color: #807c7b;
		font-size: em( 20px );
		margin-top: -2px;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translateY( -50% );
		right: 15px;
		background: transparent;
		border: 0;
		z-index: 1;

		&:hover,
		&:focus {
			color: $link-hover-color;
		}

		&:focus {
			outline: 1px solid $link-color;
			outline-offset: 0;
			transition: none;
		}
	}
}
