// ==========================================================================
// Form Styles
// ==========================================================================

#wrapper .gform_wrapper {
	form {
		@include clearfix();
	}

	.gfieldset {
		margin-bottom: 2em;
	}

	ul.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	@media only screen and ( min-width: 641px ) {
		.left_label .gfield:not(.top_label),
		.top_label .gfield.left_label {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.ginput_complex {
				margin-left: 0;
			}

			.gfield_label {
				flex-basis: 25%;
				margin-bottom: 0;
			}

			> .ginput_container {
				flex-grow: 1;
				flex-basis: 25%;
			}

			input,
			select,
			textarea {
				&.small {
					width: 50%;
				}
				&.medium {
					width: 75%;
				}
				&.large {
					width: 100%;
				}
			}

			.validation_message {
				width: 75%;
				margin-left: auto;
			}
		}

		.top_label > .gfield_label {
			float: initial;
			width: 100%;
			padding-right: 0;
			margin-bottom: 5px;
		}
	}

	.gfield {
		@include clearfix();
		margin-top: 0;
		margin-bottom: 1em;
	}

	.gfield_error {
		background-color: transparent;
		border: 0;

		label {
			color: $global-color;
		}

		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not([type=number]),
		textarea {
			border: 2px solid #767676;
		}
	}

	.gfield_required {
		display: none;
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not([type=number]),
	select,
	textarea {
		padding: ($global-spacing / 2) $global-spacing;
	}

	select {
		padding-right: ($global-spacing * 2);
			-webkit-appearance: none;
			-moz-appearance: none;
		background-image:
			linear-gradient( 45deg, transparent 50%, $global-color 50% ),
			linear-gradient( 135deg, $global-color 50%, transparent 50% );
		background-position:
			calc( 100% - 1em ),
			calc( 100% - 0.625em - 1px );
		background-size:
			0.375em 0.375em,
			0.375em 0.375em;
		background-repeat: no-repeat;
	}

	input[type=radio] {
		width: 1em !important;
		height: 1em !important;
		margin-top: 0.05em !important;
		margin-right: 0.25em !important;
	}

	.gform_footer, .gform_page_footer {
		padding: 0;
		margin: 0;
		text-align: center;

		@media only screen and ( min-width: 641px ) {
			padding-right: 16px;

			&.left_label {
				float: right;
				width: 75%;
			}
		}
	}

	.gform_button {
		margin: 0 0 1em;
		min-width: 50%;
		outline: none;
	}

	.validation_error {
		color: #c00;
		margin-bottom: $global-spacing;
		border: 0;
		text-align: initial;

		&::before {
			@include fa( 'f071' );
			margin-right: 0.25em;
		}
	}

	.validation_list {
		padding-left: 0;
		list-style: none;

		a {
			outline: none;
		}
	}

	.validation_message {
		color: #c00;

		&::before {
			@include fa( 'f071' );
			margin-right: 0.25em;
		}
	}
}

@each $input in $input-list {
	#{$input} {
		width: 100%;
		color: $global-color;
		padding: ($global-spacing / 2) $global-spacing;
		border: 2px solid #767676;
		border-radius: 0.5em;
		outline: none;

		&:focus {
			border-color: $brand-color;
		}
	}
}

select {
	width: 100%;
	color: $global-color;
	padding: ($global-spacing / 2) $global-spacing;
	border: 2px solid #767676;
	border-radius: 0.5em;
	outline: none;

	&:focus {
		border-color: $brand-color;
	}
}

textarea {
	border-radius: 0.5em;
	resize: vertical;
}

@each $button in $button-list {
	#{$button} {
		@include button-brand();
		padding: ($global-spacing / 2) $global-spacing;
		border: 2px solid transparent;
		border-radius: 15em;
		font-weight: 600;
		white-space: normal;
		transition: all 0.3s ease-in-out;

		&:focus {
			text-decoration: underline;
		}
	}
}
