// ==========================================================================
// Service Styles
// ==========================================================================

.service-item {
	padding: ($global-spacing * 2) 0;

	.row {
		position: relative;
		width: 85%;
		margin: 0 auto;

		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
		}
	}

	h2 {
		margin: 0 0 $global-spacing;
	}

	.image {
		text-align: center;

		@media screen and (min-width: 768px) {
			&.top {
				top: -($global-spacing * 2);
				align-self: flex-start;
			}

			&.bottom {
				bottom: -($global-spacing * 2);
				align-self: flex-end;
			}
		}
	}

	&.colored {
		color: #fff;

		h2 {
			color: #fff;
		}

		.image {
			order: 2;
		}
	}
}
