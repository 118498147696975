// ==========================================================================
// Navigation Styles
// ==========================================================================

.menu {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		float: none;
		display: block;

		a {
			display: inline-block;

			&:hover {
				text-decoration: none;
			}
		}

		&::before {
			display: none;
		}

		&.has-sub {
			position: relative;

			&:hover > .sub-menu {
				opacity: 1;
				transform: scale( 1, 1 );

				li {
					visibility: visible;
				}
			}
		}
	}
}

.sub-menu {
	opacity: 0;
	margin-left: 0;
	list-style: none;
	transform: scale( 1, 0 );
	transform-origin: top;
	transition: opacity 0.1s, transform 0.2s linear;
	position: absolute;
	z-index: 1000;

	li {
		width: 100%;
		visibility: hidden;
	}
}


.sitenav,
.sitelinks,
.toplinks,
.bottomlinks {
	.menu li {
		display: inline-block;
		margin: 0 rem( 35px ) 0 0;
		vertical-align: middle;

		&.is-btn > a {
			@include button-base();
			border-style: solid;
			border-color: $brand-color;

			&:hover,
			&:focus {
				background: $brand-color;
				color: #fff;
			}
		}

		a {
			padding: rem( 7px ) 0;
			border-bottom: 3px solid transparent;
			font-size: em( 18px );
			font-weight: 700;
			outline: none;
		}

		&:last-child {
			margin-right: 0;
		}

		.sub-menu {
			min-width: 260px;
			padding: $global-spacing;
			background-color: $brand-color;
			box-shadow: 0 10px 30px 3px rgba( 0, 0, 0, 0.3 );

			li {
				margin: 0;
				padding: ($global-spacing / 2) 0;

				a {
					padding: 0;
				}
			}
		}
	}
}
