/* Intopia Styles
	 ========================================================================== */

// Make viewport responsive
@-ms-viewport { width: device-width; } // sass-lint:disable-line no-vendor-prefixes

html {
	// Use percentage by default to inherit the value from the user's browser settings
	font-size: ( $rem-base / 16px ) * 100%;

	// Use a "normal", non-overlapping, non-auto-hiding scrollbar; Edge 12+, Internet Explorer 11-
	-ms-overflow-style: -ms-autohiding-scrollbar; // sass-lint:disable-line no-vendor-prefixes
}

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
	margin: 0.5em 0 0.25em;
}

h1, .h1, h2, .h2, h3, .h3 {
	font-weight: 400;
}

a {
	transition: all 0.3s ease-in-out;

	&:hover, &:focus {
		text-decoration: none;
	}

	&:focus {
		outline: 1px solid $link-color;
		outline-offset: 0;
		transition: none;
	}
}

@mixin button-base() {
	padding: ($global-spacing/2) $global-spacing;
	border-radius: 15em;
	border-width: 2px;
	font-weight: 600;
	white-space: normal;
	outline: none;

	&:hover, &:focus {
		outline: none;
	}

	&:focus {
		text-decoration: underline;
	}
}

@mixin button-brand() {
	background: $link-color;
	color: #fff;

	&:hover, &:focus {
		background: $link-hover-color;
		color: #fff;
	}
}

@mixin button-white() {
	background: #fff;
	color: $link-color;

	&:hover, &:focus {
		background: transparent;
		color: #fff !important;
		border-color: #fff;
	}
}

.btn {
	@include button-base();

	&.brand {
		@include button-brand();
	}

	&.white {
		@include button-white();
	}

	&.more {
		padding-left: ($global-spacing*2);
		padding-right: ($global-spacing*2);
		margin-top: $global-spacing;
	}
}

#skip-link {
	@include button-base();
	@include button-brand();
	position: absolute;
	top: $global-spacing;
	left: $global-spacing;
	z-index: 10;
}

img {
	height: auto;
	max-width: 100%;
}

hr {
	margin: ($global-spacing*2) auto;
	border-color: rgba( $global-color, 0.2 );
}
