// ==========================================================================
// Content Styles
// ==========================================================================

.banner,
.call2action {
	padding: ($global-spacing * 2) 0;
	background: $brand-color;
	color: #fff;

	h1,
	h3 {
		color: #fff;
	}

	h3 {
		margin: 0 0 $global-spacing;
		font-size: em( 34px );
	}

	.row {
		position: relative;
		width: 85%;
		margin: 0 auto;

		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
		}
	}

	.text {
		span {
			font-weight: 700;
		}

		.btn + .btn {
			margin-left: $global-spacing;
		}
	}

	.image {
		text-align: center;

		@media screen and (min-width: 768px) {
			&.top {
				top: -(($global-spacing * 2) + 2);
				align-self: flex-start;
			}

			&.bottom {
				bottom: -($global-spacing * 2);
				align-self: flex-end;
			}
		}
	}

	p a,
	.tags a {
		color: #fff;
		border-bottom: 1px solid #fff;

		&:last-child {
			margin-right: 0;
		}
	}

	.buttons {
		margin: 2em 0 1em;
	}

	#wrapper & .gform_wrapper {
		margin: 2em 0 0;

		.ginput_container input {
			background: transparent;
			border-color: #fff;
			color: #fff;

			&:focus {
				background: #fff;
				color: #000;
			}
		}

		.gform_footer {
			text-align: left;

			.gform_button {
				background: #fff;
				color: $brand-color;

				&:hover,
				&:focus {
					background: transparent;
					border-color: #fff;
					color: #fff;
				}

				@media screen and (min-width: 481px) {
					width: auto;
					min-width: 75%;
				}
			}
		}
	}
}


.breadcrumbs {
	width: 85%;
	min-height: 2rem;
	margin: 0 auto;
	padding: 0 15px;
	line-height: 2rem;

	a {
		color: #fff;
		padding-bottom: 1px;
		border-bottom: 1px solid #fff;
	}

	.breadcrumb_last {
		font-weight: 700;
	}
}


.the_content {
	width: 85%;
	max-width: 35em;
	margin: ($global-spacing * 3) auto;
	padding: 0 15px;

	a:not( .btn ) {
		padding-bottom: 1px;
		border-bottom: 1px solid $brand-color;
	}
}
