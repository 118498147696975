// ==========================================================================
// Responsive Styles
// ==========================================================================

@media only screen and ( min-width: 992px ) {
	.site-header {
		.container {
			position: relative;
		}

		.bottomsection {
			display: table;

			.branding,
			.sitenav {
				display: table-cell;
				vertical-align: bottom;
				float: none;
			}
		}
	}

	#offcanvas-toggle {
		display: none;
	}
}

@media only screen and ( max-width: 991px ) {
	.site-header {
		.bottomsection {
			position: relative;
			margin-top: 0;
		}

		.sitenav,
		.topsection {
			display: none;
		}
	}
}
