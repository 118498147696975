// ==========================================================================
// Event Styles
// ==========================================================================

.single-event {
	.info.row {
		width: 85%;
		margin: ($global-spacing * 2) auto;

		span {
			display: block;
			font-weight: 700;
		}
	}
}
