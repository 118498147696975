// ==========================================================================
// Footer Styles
// ==========================================================================

.testimonials-section {
	padding: 3em 0;

	h2 {
		margin: 0 0 ($global-spacing * 2);
		text-align: center;
		font-size: 2.5em;
	}

	.testimonial {
		text-align: center;

		.message {
			padding: $global-spacing;
			font-size: 1.125em;
		}

		.info {
			padding: $global-spacing;
			font-size: 0.875em;
		}
	}
}

.testimonial-slider {
	.slick-dots {
		margin: $global-spacing 0;
		display: block;
		width: 100%;
		padding: 0;
		list-style: none;
		font-size: 0;
		line-height: 0;
		text-align: center;

		li {
			position: relative;
			display: inline-block;
			width: 15px;
			height: 15px;
			margin: 0 5px;
			padding: 0;
			cursor: pointer;

			&.slick-active {
				button {
					background: #807c7b;
					opacity: 0.5;
				}
			}
		}

		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 15px;
			height: 15px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: $global-color;
			border-radius: 50%;
		}
	}
}

.clients-section {
	padding: 3em 0;

	h2 {
		margin: 0 0 ($global-spacing * 2);
		text-align: center;
		font-size: 2.5em;
	}

	.client {
		margin-top: $global-spacing;
		text-align: center;

		@media screen and (min-width: 768px) {
			margin-top: 0;
		}
	}
}

.newsletter {
	padding: ($global-spacing * 2) 0;
	background: $newsletter-color;
	color: #fff;

	h2 {
		margin-top: 0;
		color: #fff;
		font-size: 2.25em;
	}

	.gform_wrapper {
		margin-top: $global-spacing * 2;

		.ginput_container {
			input {
				border-color: #fff;

				&:focus {
					border-color: #000;
				}
			}
		}

		.gform_footer .gform_button {
			background: transparent;
			border-color: #fff;
			font-weight: 600;

			&:hover,
			&:focus {
				background: darken( $newsletter-color, 3 );
			}

			&:focus {
				text-decoration: underline;
			}

			@media screen and (min-width: 481px) {
				width: 100%;
			}
		}
	}

	.validation_error,
	.validation_list a,
	.validation_message {
		color: #fff;
	}

	.validation_list a:hover,
	.validation_list a:focus {
		text-decoration: underline;
	}

	.socials {
		margin: 2rem 0;

		a {
			color: $newsletter-color;

			&:hover,
			&:focus {
				color: #fff;

				.fa-circle {
					color: darken( $newsletter-color, 5 );
				}
			}
		}

		.fa-circle {
			color: #fff;
		}

		@media screen and (min-width: 481px) {
			float: right;
			width: 80%;
		}
	}
}

.site-footer {
	text-align: center;
}

.sitelinks {
	padding: ($global-spacing * 2) 0;
	background: $brand-color;

	li:hover,
	li.active {
		a {
			border-bottom-color: #fff;

			&:focus {
				outline: 1px solid #fff;
			}
		}
	}

	li a:focus {
		border-bottom-color: #fff;
	}

	a {
		color: #fff;
	}
}

.copyright {
	padding: ($global-spacing * 2) 0;

	p {
		color: $copyright-color;
		margin-bottom: 0;
		font-size: em( 14px );
		font-weight: 400;
		opacity: 0.7;

		a {
			text-decoration: underline;
		}
	}

	a {
		color: $copyright-color;

		&:hover,
		&:focus {
			color: $brand-color;
			text-decoration: underline;
		}
	}
}

.logos {
	margin-top: rem( 30px );

	span {
		margin: 0 ($global-spacing / 2);
	}

	img {
		width: 75px;
	}
}

.bottomlinks {
	margin-bottom: rem( 30px );

	li:hover,
	li.active {
		a {
			border-bottom-color: $global-color;
		}
	}

	li a:focus {
		color: $global-color;
		border-bottom-color: $global-color;
	}

	a {
		color: $global-color;

		&:hover,
		&:focus {
			color: $global-color;
		}
	}
}
