// ==========================================================================
// Home Styles
// ==========================================================================

.home {
	.banner {
		h2 {
			color: #fff;
			font-size: 2.5em;
		}

		.text {
			margin: $global-spacing auto;
			padding: 0 15px;
			position: relative;
			z-index: 1;
		}

		.container {
			position: relative;
		}

		.image {
			text-align: center;

			@media screen and (min-width: 768px) {
				&.top,
				&.bottom {
					position: absolute;
					right: 0;
					width: 50%;
				}

				&.top {
					top: -($global-spacing * 2);
				}

				&.bottom {
					bottom: -($global-spacing * 2);
				}
			}
		}
	}

	.service-section,
	.key-section,
	.call2action {
		padding: ($global-spacing * 2) 0;
		color: #fff;

		h2 {
			color: #fff;
			margin: 0 0 $global-spacing;
			font-size: 2.5em;
		}
	}

	.service-section {
		img {
			display: block;
			margin: 1rem auto;

			@media screen and (min-width: 768px) {
				position: absolute;
			}
		}
	}

	.key-section {
		.row {
			list-style: none;
			padding: 0;
			margin-bottom: 0;
		}

		.item {
			margin-bottom: $global-spacing;
			font-size: 1.15em;
			font-weight: 700;

			@media screen and (max-width: 480px) {
				padding-left: 1.75em;
				text-indent: -0.45em;
			}
		}

		a {
			padding: rem( 8px );
			border-bottom: 2px solid #fff;
			color: #fff;
			line-height: 2;
			outline: none;

			&:hover,
			&:focus {
				background: #fff;
			}
		}

		h3 {
			color: #fff;
			margin: 0 0 $global-spacing;
			font-size: 2.1em;
		}
	}

	.work-section {
		padding: 3em 0;
		background-repeat: no-repeat;
		background-position: bottom;

		h2 {
			margin: 0 0 $global-spacing;
			font-size: 2.5em;
		}

		.image {
			bottom: -1.5em;
			z-index: -1;
		}
	}

	.call2action {
		.the_content {
			text-align: center;

			@media screen and (min-width: 768px) {
				width: 75%;
			}
		}
	}

	.related-items {
		padding: ($global-spacing * 2) 0;
	}

	.section-title {
		font-size: 2.5em;
		font-weight: 400;
	}
}
